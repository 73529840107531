/* font-family: 'Abril Fatface', cursive;
font-family: 'Kaisei Opti', serif;
font-family: 'Shippori Mincho', serif; */
svg.logoIntro{
  max-width: 50vw;
  max-height: 60vh;
  /* background: #fff; */
}
html{
  overflow: none;
  overflow-x: hidden;
  /* overflow-x: none; */
  font-size: 20px;
}
.App {
  text-align: center;
}

.Loading{
  color: #fff;
  font-size: 1.5rem;
  font-family: 'Kaisei Opti', serif;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background: rgb(255,111,175);
  background: -moz-radial-gradient(circle, rgba(255,111,175,1) 0%, rgba(0,0,0,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(255,111,175,1) 0%, rgba(0,0,0,1) 100%);
  background: radial-gradient(circle, rgba(255,111,175,1) 0%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff6faf",endColorstr="#000000",GradientType=1);

}

.Loading svg {
  max-height: 60vh;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

img.above {
  position: absolute;
  left: 20%;
  top: 60px;
  z-index: 10;
  width: 60%;
}

.jumboText{
  font-size: 13vw;
  font-family: 'Shippori Mincho', serif; 
  font-weight: 700;
}


.largeText{
  font-size: 12vw;
  font-family: 'Shippori Mincho', serif; 
  font-weight: 700;
  margin: 60px auto 60px auto;
  /* border-bottom: 2px solid #a5a5a5; */
}
.largeText1{
  font-size: 10vw;
  font-family: 'Shippori Mincho', serif; 
  font-weight: 700;
  margin: 60px auto 60px auto;
}

.imgScreenHeight{
  /* max-width: 70vw; */
  /* max-height: 50vh !important; */
  height: 600px;

  max-width: 40vw!important;

}

.adDiscount{
  /* add flex and center */
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebottle{
  /* max-width: 80%; */
}

/* .removeSideScroll{
  overflow-x: hidden !important;
} */

.JuneHeart{
  max-width: 60vw;
}
.adverisementDisplay{
  background-color: white;
  padding: 40px;
  min-height: 40vh;
  max-width: 80%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.refundingBackground{
  max-width: 100vw;
  padding: 60px 0;
  min-height: 100vh;
  background: rgb(84,84,84);
  background: -moz-radial-gradient(circle, rgba(84,84,84,1) 0%, rgba(0,0,0,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(84,84,84,1) 0%, rgba(0,0,0,1) 100%);
  background: radial-gradient(circle, rgba(84,84,84,1) 0%, rgba(0,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#545454",endColorstr="#000000",GradientType=1);
}
.imgIntroScreenHeight{
  max-height: 50vh !important;
}
.centeredFlex{
  display: flex;
  justify-content: center;
  align-items: center;

}

.noPatterBackground{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

}
.fixedlargeBackground{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}


.junegalSection{
  padding-top:30px;
  background: black;
  padding: 0 auto;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  justify-content: center;
  /* flex-direction: column; */

}
.junegalSection img{
  width:100%;
}

.junegalImage{
  min-height: 50vh
}


.juneGalText{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
  padding: 30px 5px;
  margin: 0 auto;

background: rgb(255,204,248);
background: -moz-linear-gradient(90deg, rgba(255,204,248,1) 0%, rgba(247,247,247,0.9978700953037465) 23%, rgba(247,247,247,1) 66%, rgba(255,204,248,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(255,204,248,1) 0%, rgba(247,247,247,0.9978700953037465) 23%, rgba(247,247,247,1) 66%, rgba(255,204,248,1) 100%);
background: linear-gradient(90deg, rgba(255,204,248,1) 0%, rgba(247,247,247,0.9978700953037465) 23%, rgba(247,247,247,1) 66%, rgba(255,204,248,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffccf8",endColorstr="#ffccf8",GradientType=1);

}

.juneGalText p {
  width: 75%;
  font-size: 25px;
}
.messageBackground{
  -webkit-box-shadow: inset 0px 45px 52px -51px rgba(0,0,0,0.93);
  -moz-box-shadow: inset 0px 45px 52px -51px rgba(0,0,0,0.93);
  box-shadow: inset 0px 45px 52px -51px rgba(0,0,0,0.93);
padding: 70px 3px;
}

.topShadow{
  -webkit-box-shadow: inset 0px 45px 52px -51px rgba(0,0,0,0.93);
  -moz-box-shadow: inset 0px 45px 52px -51px rgba(0,0,0,0.93);
  box-shadow: inset 0px 45px 52px -51px rgba(0,0,0,0.93);
}

.messageMainArea{
  /* display: grid;
  grid-template-columns: 1fr; */
  /* overflow-wrap: break-word; */
  /* max-width: 90%; */
  /* gap: 20px; */
  }

.messageMainArea div{
  /* overflow-wrap: break-word !important; */
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.messageMainArea div p{
  overflow-wrap: break-word !important;
  max-width: 60%;
  margin: auto;
}

/* Center an hr inside messageMainArea */
.messageMainArea hr{
  width: 50%;
  margin: 20px auto;
}

.blueGradient{
  background: rgb(201,243,255);
background: -moz-linear-gradient(90deg, rgba(201,243,255,1) 0%, rgba(247,247,247,0.9978700953037465) 47%, rgba(204,244,255,1) 100%);
background: -webkit-linear-gradient(90deg, rgba(201,243,255,1) 0%, rgba(247,247,247,0.9978700953037465) 47%, rgba(204,244,255,1) 100%);
background: linear-gradient(90deg, rgba(201,243,255,1) 0%, rgba(247,247,247,0.9978700953037465) 47%, rgba(204,244,255,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c9f3ff",endColorstr="#ccf4ff",GradientType=1);
}
.minScreenHeight {
  min-height: 100vh;
}
.imgShadow{
  /* make shadow for transparent images */  
  /* -webkit-filter: drop-shadow(0px 0px 0px rgba(1, 1, 0, 1.88)); */
  /* filter: drop-shadow(0px 0px 0px rgba(0,0,0,0.5)); */
  filter: drop-shadow(5px 5px 10px #000);
  -webkit-filter: drop-shadow(5px 5px 10px #000);
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.mobileHiddenElements{
  display: block;
}


.button {
  border-radius: 4px;
  background-color: #f4511e;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 28px;
  padding: 20px;
  /* width: 400px !important; */
  transition: all 0.5s;
  cursor: pointer;
  margin: 5px;
}

.button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.button:hover span {
  padding-right: 25px;
  color: #FFFFFF;
}

.button:hover span:after {
  opacity: 1;
  right: 0;
  color: #FFFFFF;
}












@media only screen and (max-width: 768px) {
  
  .hiddenElements, .mobileHiddenElements {
    display: none !important;
  }

.showMobileElements{
  display: block !important;
}

.messageMainArea div p{
  overflow-wrap: break-word !important;
  max-width: 80%;
  margin: auto;
}

.adverisementDisplay{
  padding: 20px;
  min-height: 40vh;
  max-width: 90%;
}

.refundingBackground{
  max-width: 100%;
}
svg.logoIntro{
  max-width: none;
  max-height: 80vh;
  /* background: #fff; */
}
}

@media only screen and (min-width: 769px) {
  
  .hiddenElements {
    display: block !important;
  }
  
  .mobileHiddenElements{
    display: none !important;
  }
  
  .showMobileElements{
    display: none !important;
  }
svg.logoIntro{
  max-width: 60vw;
}
  .imgIntroScreenHeight{
    max-width: 40vw;
    height: 400px !important
    ;
  
  }
}

